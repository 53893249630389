import React from 'react';

import { useDataQuery } from '@crf/ui/common';
import { TCrf } from '@crf/ui/crf/interfaces';

type UseCrfProps = {
  id: string | undefined;
};

type UseCrfReturn = {
  data: TCrf | null;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  isRefetching: boolean;
  refetch: () => void;
};

export const useCrf = ({ id }: UseCrfProps): UseCrfReturn => {
  const url = React.useMemo(() => {
    const url = `/api/crfs/${id}`;
    return url;
  }, [id]);

  const { data, isLoading, isRefetching, refetch, error, isError } = useDataQuery<TCrf>({ url });

  return {
    data,
    isError,
    error,
    refetch,
    isLoading,
    isRefetching
  };
};
