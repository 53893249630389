import { type MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { TCrf } from '@crf/ui/crf/interfaces';

type TUseTableColumnsReturn = {
  columns: MRT_ColumnDef<TCrf>[];
};

/**
 * @public
 */

export const useTableColumns = (): TUseTableColumnsReturn => {
  const columns = React.useMemo<MRT_ColumnDef<TCrf>[]>(
    () => [
      {
        id: 'view',
        header: 'View',
        size: 50,
        accessorFn: (row) => row.internal_id,
        Cell: ({ renderedCellValue }) => (
          <Box component={Link} to={`${renderedCellValue}`} fontWeight={600} letterSpacing=".04em">
            View
          </Box>
        )
      },
      {
        accessorKey: 'crf',
        header: 'CRF',
        minSize: 50, //min size enforced during resizing
        maxSize: 100, //max size enforced during resizing
        size: 80 //medium column
      },
      { accessorKey: 'project_title', header: 'Project' },
      { accessorKey: 'crf_type', header: 'Type' },
      { accessorKey: 'date_of_request', header: 'Request Date' },
      { accessorKey: 'supplier', header: 'Supplier' },
      { accessorKey: 'company', header: 'Company' },
      { accessorKey: 'approved_in_budget', header: 'In Budget' },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              color:
                cell.getValue<string>() && cell.getValue<string>().toLowerCase().includes('pending')
                  ? 'rgba(0,103,181,1)'
                  : 'rgb(51, 153, 71)',
              fontWeight: 600
            }}>
            {cell.getValue<string>() && cell.getValue<string>()}
          </Box>
        )
      },
      {
        accessorKey: 'approval_stage',
        header: 'Stage',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              color:
                cell.getValue<string>() &&
                cell.getValue<string>().toLowerCase().includes('approved')
                  ? 'rgb(51, 153, 71)'
                  : 'rgb(164, 136, 10)',
              fontWeight: 600
            }}>
            {cell.getValue<string>() && cell.getValue<string>()}
          </Box>
        )
      }
    ],
    []
  );

  return { columns };
};
