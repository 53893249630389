import React from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { TCrf } from '@crf/ui/crf/interfaces';

type TTopDetailsProps = {
  crf: TCrf | undefined;
};

export const TopDetails: React.FC<TTopDetailsProps> = ({ crf }) => {
  const base64toBlob = (data: string) => {
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    const blob = new Blob([out], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  };

  const openDocumentInPopUp = (document: string) => {
    const blob = base64toBlob(`data:application/pdf;base64,${document}`);
    const documentWindow = window.open(blob, 'Quote', `width=600,height=500`);
    return documentWindow?.focus();
  };

  return (
    <React.Fragment>
      {crf && (
        <Box marginBottom="1.5rem">
          <Typography
            component="h1"
            fontSize="1rem"
            lineHeight="1"
            letterSpacing=".04em"
            fontWeight={700}
            marginBottom="1.5rem"
            textAlign="center"
          >
            Data Centre - Capital Spend Application
          </Typography>

          <Box marginBottom="1.5rem">
            <Box sx={{ display: { md: 'none' } }}>
              <React.Fragment>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Reference Request Number
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">{crf.crf !== '' ? crf.crf : 'N/A'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
              <React.Fragment>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Data Centre Project Title
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {crf.project_title !== '' ? crf.project_title : 'N/A'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
              <React.Fragment>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Project Requester
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {crf.requestor !== '' ? crf.requestor : 'N/A'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
              <React.Fragment>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Project Sponsor/(CFO)
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {crf.sponsor !== '' ? crf.sponsor : 'N/A'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
              <React.Fragment>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Date of Request
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {crf.date_of_request !== '' ? crf.date_of_request : 'N/A'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
              <React.Fragment>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Financial Q/Y
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {crf.financial_q_y !== '' ? crf.financial_q_y : 'N/A'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontSize: '.8125rem',
                      fontWeight: 500,
                      width: '25%',
                      border: `1px solid ${grey[400]}`,
                      '&.head': {
                        bgcolor: grey[300],
                        fontWeight: 700
                      }
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell className="head">Data Centre Project Title</TableCell>
                      <TableCell>{crf.project_title !== '' ? crf.project_title : 'N/A'}</TableCell>
                      <TableCell className="head">Reference Request Number</TableCell>
                      <TableCell>{crf.crf !== '' ? crf.crf : 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="head">Project Requester</TableCell>
                      <TableCell>{crf.requestor !== '' ? crf.requestor : 'N/A'}</TableCell>
                      <TableCell className="head">Project Sponsor/(CFO)</TableCell>
                      <TableCell>{crf.sponsor !== '' ? crf.sponsor : 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="head">Date of Request</TableCell>
                      <TableCell>
                        {crf.date_of_request !== '' ? crf.date_of_request : 'N/A'}
                      </TableCell>
                      <TableCell className="head">Financial Q/Y</TableCell>
                      <TableCell>{crf.financial_q_y !== '' ? crf.financial_q_y : 'N/A'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box marginBottom="1.5rem">
            <Box sx={{ display: { md: 'none' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontWeight: 700,
                      bgcolor: grey[300],
                      fontSize: '.8125rem'
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: `1px solid ${grey[400]}`
                        }}
                      >
                        Type
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component="div">
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontWeight: 500,
                      fontSize: '.8125rem',
                      border: `1px solid ${grey[400]}`,
                      borderTop: 0
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        {crf.crf_type !== '' ? crf.crf_type : 'N/A'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontSize: '.8125rem',
                      fontWeight: 500,
                      border: `1px solid ${grey[300]}`
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        width="25%"
                        sx={{
                          borderRight: `1px solid ${grey[300]}`,
                          fontWeight: '700 !important',
                          bgcolor: grey[300]
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell>{crf.crf_type !== '' ? crf.crf_type : 'N/A'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box marginBottom="1.5rem">
            <Box sx={{ display: { md: 'none' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontWeight: 700,
                      bgcolor: grey[300],
                      fontSize: '.8125rem'
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: `1px solid ${grey[400]}`
                        }}
                      >
                        Requesting Company
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component="div">
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontWeight: 500,
                      fontSize: '.8125rem',
                      border: `1px solid ${grey[400]}`,
                      borderTop: 0
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">{crf.company !== '' ? crf.company : 'N/A'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontSize: '.8125rem',
                      fontWeight: 500,
                      border: `1px solid ${grey[300]}`
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        width="25%"
                        sx={{
                          borderRight: `1px solid ${grey[300]}`,
                          fontWeight: '700 !important',
                          bgcolor: grey[300]
                        }}
                      >
                        Requesting Company
                      </TableCell>
                      <TableCell>{crf.company !== '' ? crf.company : 'N/A'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box marginBottom="1rem">
            <Box sx={{ display: { md: 'none' } }}>
              <Box marginBottom="1.5rem">
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Quote
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {crf.quote !== undefined &&
                          crf.quote !== null &&
                          crf.quote.trim() !== '' ? (
                            <Box
                              component={Link}
                              to="#"
                              onClick={(_e: any) => openDocumentInPopUp(crf.quote)}
                              sx={{
                                color: '#0036a0',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                textOverflow: 'ellipsis',
                                '&:hover': {
                                  textDecoration: 'underline'
                                }
                              }}
                            >
                              {`Preview - Quote, ${crf.project_title}.pdf`}
                            </Box>
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 700,
                        bgcolor: grey[300],
                        fontSize: '.8125rem'
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          width="2rem"
                          sx={{
                            borderRight: `1px solid ${grey[400]}`
                          }}
                        >
                          Additional Quotes
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component="div">
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        fontSize: '.8125rem',
                        border: `1px solid ${grey[400]}`,
                        borderTop: 0
                      }
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {crf.additional_quote !== undefined &&
                          crf.additional_quote !== null &&
                          crf.additional_quote.trim() !== '' ? (
                            <Box
                              component={Link}
                              to="#"
                              onClick={(_e) => openDocumentInPopUp(crf.additional_quote)}
                              sx={{
                                color: '#0036a0',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                textOverflow: 'ellipsis',
                                '&:hover': {
                                  textDecoration: 'underline'
                                }
                              }}
                            >
                              {`Preview - Additional Quotes, ${crf.project_title}.pdf`}
                            </Box>
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontSize: '.8125rem',
                      fontWeight: 500,
                      border: `1px solid ${grey[300]}`
                    }
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        width="25%"
                        sx={{
                          borderRight: `1px solid ${grey[300]} !important`,
                          borderBottom: `1px solid ${grey[400]} !important`,
                          fontWeight: '700 !important',
                          bgcolor: grey[300]
                        }}
                      >
                        Quote
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderBottom: `1px solid ${grey[400]} !important`
                        }}
                      >
                        {crf.quote !== undefined &&
                        crf.quote !== null &&
                        crf.quote.trim() !== '' ? (
                          <Box
                            component={Link}
                            to="#"
                            onClick={(_e: any) => openDocumentInPopUp(crf.quote)}
                            sx={{
                              color: '#0036a0',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              textOverflow: 'ellipsis',
                              '&:hover': {
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {`Preview - Quote, ${crf.project_title}.pdf`}
                          </Box>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        colSpan={1}
                        sx={{
                          borderRight: `1px solid ${grey[400]}`,
                          borderBottom: `1px solid ${grey[400]}`,
                          fontWeight: '700 !important',
                          bgcolor: grey[300]
                        }}
                      >
                        Additional Quotes
                      </TableCell>
                      <TableCell align="left">
                        {crf.additional_quote !== undefined &&
                        crf.additional_quote !== null &&
                        crf.additional_quote.trim() !== '' ? (
                          <Box
                            component={Link}
                            to="#"
                            onClick={(_e) => openDocumentInPopUp(crf.additional_quote)}
                            sx={{
                              color: '#0036a0',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              textOverflow: 'ellipsis',
                              '&:hover': {
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {`Preview - Additional Quotes, ${crf.project_title}.pdf`}
                          </Box>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
