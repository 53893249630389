import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Fab } from '@mui/material';
import { common, green, red } from '@mui/material/colors';

type ProcessingFabProps = {
  isProcessing: boolean;
  isSuccess: boolean;
  isError: boolean;
};

export const ProcessingFab: React.FC<ProcessingFabProps> = ({
  isError,
  isSuccess,
  isProcessing
}) => {
  const buttonSx = {
    ...(isSuccess && {
      bgcolor: green[500],
      color: common.white,
      '&:hover': {
        bgcolor: green[700],
        color: common.white
      },
      ...(isError && {
        bgcolor: red[500],
        color: common.white,
        '&:hover': {
          bgcolor: red[700],
          color: common.white
        }
      }),
      ...(isProcessing && {
        bgcolor: common.white,
        color: common.black,
        '&:hover': {
          bgcolor: common.white,
          color: common.black
        }
      })
    })
  };

  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <Fab aria-label="http" color="default" sx={buttonSx}>
        {isSuccess ? <CheckIcon /> : isError ? <CloseIcon /> : <AccessTimeIcon />}
      </Fab>
      {isProcessing && (
        <CircularProgress
          size={68}
          sx={{
            color: 'primary',
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
          }}
        />
      )}
    </Box>
  );
};
