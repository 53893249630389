import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosHttp } from '@crf/ui/api/axios';
import { TAxiosGetProps } from '@crf/ui/api/interfaces';

interface TUseReactQueryProps<T> extends TAxiosGetProps {
  options: Omit<UseQueryOptions<unknown, Error, T, string[]>, 'initialData' | 'queryFn'>;
}

export const useReactQuery = <T>(props: TUseReactQueryProps<T>) => {
  if (!props.options.gcTime) props.options.gcTime = 5 * 60 * 1000;
  if (!props.options.staleTime) props.options.staleTime = 5 * 60 * 1000;
  return useQuery({
    ...props.options,
    queryKey: props.options.queryKey ?? ['api', props.url],
    queryFn: async () => await axiosHttp.get<T>(props)
  });
};
