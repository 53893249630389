import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import { AuthProvider } from '@crf/ui/auth';
import {
    GlobalStateProvider,
    LoadingResource,
    ReactQueryProvider,
    routing,
    theme
} from '@crf/ui/common';

import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

const pdfRegex = /^\/pdf\/.*/;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<LoadingResource />}>
        <ReactQueryProvider>
          <GlobalStateProvider>
            <AuthProvider>
              {pdfRegex.test(window.location.pathname) ? (
                <React.Fragment />
              ) : (
                <RouterProvider router={routing} />
              )}
            </AuthProvider>
          </GlobalStateProvider>
        </ReactQueryProvider>
      </React.Suspense>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
