import React from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { TCrf } from '@crf/ui/crf/interfaces';

type TFinancialSummaryProps = {
  crf: TCrf | undefined;
};

export const FinancialSummary: React.FC<TFinancialSummaryProps> = ({ crf }) => {
  return (
    <React.Fragment>
      {crf && (
        <Box marginBottom="1.5rem">
          <Typography
            component="h1"
            fontSize="1rem"
            lineHeight="1"
            fontWeight={700}
            textAlign="center"
            letterSpacing=".04em"
            marginBottom="1.5rem">
            Section 1: Financial Summary
          </Typography>

          <Box marginBottom="1.5rem">
            <Box sx={{ display: { md: 'none' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontWeight: 500,
                      fontSize: '.8125rem'
                    }
                  }}>
                  <TableHead
                    sx={{
                      '& .MuiTableCell-root': {
                        bgcolor: grey[300],
                        borderTop: 'Opx !important',
                        fontWeight: '700 !important',
                        borderBottom: 'Opx !important',
                        border: `1px solid ${grey[400]}`
                      }
                    }}>
                    <TableRow>
                      <TableCell align="center" colSpan={4} sx={{ borderLeft: 'Opx !important' }}>
                        Description
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& .MuiTableCell-root': {
                        border: `1px solid ${grey[300]}`,
                        '&.header': {
                          border: `1px solid ${grey[400]}`,
                          borderRight: `1px solid ${grey[300]}`,
                          fontWeight: '700 !important',
                          bgcolor: grey[300],
                          width: '2rem'
                        },
                        '&.subhead': { fontWeight: '700 !important' }
                      }
                    }}>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.0
                      </TableCell>
                      <TableCell className="subhead" colSpan={3}>
                        Total Request Amount
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        Rate
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        ROE
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        {crf.currency} {crf.total}
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        N/A
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.1
                      </TableCell>
                      <TableCell className="subhead" colSpan={3}>
                        Is spend approved in budget? Y/N
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {crf.approved_in_budget && crf.approved_in_budget !== ''
                          ? crf.approved_in_budget
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.2
                      </TableCell>
                      <TableCell className="subhead" colSpan={3}>
                        Is spend in cash flow? Y/N
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {crf.approved_in_cashflow && crf.approved_in_budget !== ''
                          ? crf.approved_in_budget
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.3
                      </TableCell>
                      <TableCell className="subhead" colSpan={3}>
                        Budget Reference name/date
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {crf.budget_reference_name && crf.budget_reference_name !== ''
                          ? crf.budget_reference_name
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.4
                      </TableCell>
                      <TableCell className="subhead" colSpan={3}>
                        Initial spend date
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {crf.initial_spend_date && crf.initial_spend_date !== ''
                          ? crf.initial_spend_date
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.5
                      </TableCell>
                      <TableCell className="subhead" colSpan={3}>
                        Final spend date
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {crf.final_spend_date && crf.final_spend_date !== ''
                          ? crf.final_spend_date
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    '& .MuiTableCell-root': {
                      fontWeight: 500,
                      fontSize: '.8125rem'
                    }
                  }}>
                  <TableHead
                    sx={{
                      '& .MuiTableCell-root': {
                        bgcolor: grey[300],
                        borderTop: 'Opx !important',
                        fontWeight: '700 !important',
                        borderBottom: 'Opx !important',
                        border: `1px solid ${grey[400]}`
                      }
                    }}>
                    <TableRow>
                      <TableCell align="center" colSpan={2} sx={{ borderLeft: 'Opx !important' }}>
                        Description
                      </TableCell>
                      <TableCell align="center">Rate</TableCell>
                      <TableCell align="center" sx={{ borderRight: 'Opx !important' }}>
                        ROE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& .MuiTableCell-root': {
                        border: `1px solid ${grey[300]}`,
                        '&.header': {
                          border: `1px solid ${grey[400]}`,
                          borderRight: `1px solid ${grey[300]}`,
                          fontWeight: '700 !important',
                          bgcolor: grey[300],
                          width: '2rem'
                        },
                        '&.subhead': { fontWeight: '700 !important' }
                      }
                    }}>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.0
                      </TableCell>
                      <TableCell className="subhead">Total Request Amount</TableCell>
                      <TableCell>
                        {crf.currency} {crf.total}
                      </TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className="header">
                        1.1
                      </TableCell>
                      <TableCell className="subhead">Is spend approved in budget? Y/N</TableCell>
                      <TableCell colSpan={2}>
                        {crf.approved_in_budget && crf.approved_in_budget !== ''
                          ? crf.approved_in_budget
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">1.2</TableCell>
                      <TableCell className="subhead">Is spend in cash flow? Y/N</TableCell>
                      <TableCell colSpan={2}>
                        {crf.approved_in_cashflow && crf.approved_in_cashflow !== ''
                          ? crf.approved_in_cashflow
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">1.3</TableCell>
                      <TableCell className="subhead">Budget Reference name/date</TableCell>
                      <TableCell colSpan={2}>
                        {crf.budget_reference_name && crf.budget_reference_name !== ''
                          ? crf.budget_reference_name
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">1.4</TableCell>
                      <TableCell className="subhead">Initial spend date</TableCell>
                      <TableCell colSpan={2}>
                        {crf.initial_spend_date && crf.initial_spend_date !== ''
                          ? crf.initial_spend_date
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">1.5</TableCell>
                      <TableCell className="subhead">Final spend date</TableCell>
                      <TableCell colSpan={2}>
                        {crf.final_spend_date && crf.final_spend_date !== ''
                          ? crf.final_spend_date
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box marginBottom="1.5rem">
            <TableContainer component={Paper}>
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 700,
                    fontSize: '.8125rem',
                    bgcolor: grey[300]
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      width="2rem"
                      sx={{
                        borderRight: `1px solid ${grey[400]}`
                      }}>
                      1.6
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      Vendor
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component="div">
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 500,
                    fontSize: '.8125rem',
                    border: `1px solid ${grey[400]}`
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      dangerouslySetInnerHTML={{
                        __html: `${crf.supplier !== undefined && crf.supplier !== '' ? crf.supplier : 'N/A'}`
                      }}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box marginBottom="1.5rem">
            <TableContainer component={Paper}>
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 700,
                    fontSize: '.8125rem',
                    bgcolor: grey[300]
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      width="2rem"
                      sx={{
                        borderRight: `1px solid ${grey[400]}`
                      }}>
                      1.7
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      Project Funding Justification (fit in relating to approved capital programme)
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component="div">
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 500,
                    fontSize: '.8125rem',
                    border: `1px solid ${grey[400]}`,
                    borderTop: 0
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      dangerouslySetInnerHTML={{
                        __html: `${
                          crf.project_justification !== undefined &&
                          crf.project_justification !== ''
                            ? crf.project_justification
                            : 'N/A'
                        }`
                      }}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box marginBottom="1.5rem">
            <TableContainer component={Paper}>
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 700,
                    fontSize: '.8125rem',
                    bgcolor: grey[300]
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      width="2rem"
                      sx={{
                        borderRight: `1px solid ${grey[400]}`
                      }}>
                      1.8
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      Summary/Description
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component="div">
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 500,
                    fontSize: '.8125rem',
                    border: `1px solid ${grey[400]}`,
                    borderTop: 0
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      dangerouslySetInnerHTML={{
                        __html: `${crf.summary !== undefined && crf.summary !== '' ? crf.summary : 'N/A'}`
                      }}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box>
            <TableContainer component={Paper}>
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 700,
                    bgcolor: grey[300],
                    fontSize: '.8125rem'
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      width="2rem"
                      sx={{
                        borderRight: `1px solid ${grey[400]}`
                      }}>
                      1.9
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      Quotes/Single source justification?
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component="div">
              <Table
                size="small"
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 500,
                    fontSize: '.8125rem',
                    border: `1px solid ${grey[400]}`,
                    borderTop: 0
                  }
                }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      dangerouslySetInnerHTML={{
                        __html: `${
                          crf.justification !== undefined && crf.justification !== ''
                            ? crf.justification
                            : 'N/A'
                        }`
                      }}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
