/* eslint-disable react/jsx-pascal-case */
import {
  MRT_GlobalFilterTextField,
  MRT_PaginationState,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import React from 'react';

import { Box } from '@mui/material';

import { useTableColumns } from '@crf/ui/crf/hooks';
import { TCrf } from '@crf/ui/crf/interfaces';

import { DataTableTitle } from './DataTableTitle';

type TDataTableProps = {
  data: TCrf[];
  isLoading: boolean;
  tableTitle: string;
  tablePreTitle: string;
};

/**
 * @public
 */

export const DataTable: React.FC<TDataTableProps> = ({
  data,
  isLoading,
  tableTitle,
  tablePreTitle
}) => {
  const { columns } = useTableColumns();

  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25
  });

  const table = useMaterialReactTable({
    columns,
    data,
    state: { isLoading, pagination },
    enableHiding: false,
    enablePagination: true,
    enableStickyHeader: true,
    enableColumnPinning: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    paginationDisplayMode: 'pages',
    onPaginationChange: setPagination,
    //
    muiTablePaperProps: {
      sx: { display: 'flex', flexDirection: 'column', flexGrow: 1 }
    },
    muiTopToolbarProps: {
      sx: { flexShrink: 0 }
    },
    muiTableContainerProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxHeight: '100%'
      }
    },
    muiBottomToolbarProps: { sx: { flexShrink: 0 } },
    muiTableHeadCellProps: { sx: { fontSize: '13px', lineHeight: 1.5 } },
    muiTableBodyCellProps: { sx: { fontSize: '13px', lineHeight: 1.5 } },
    muiTableFooterCellProps: { sx: { fontSize: '13px', lineHeight: 1.5 } },
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined'
    },
    muiPaginationProps: {
      showLastButton: false,
      showFirstButton: false,
      rowsPerPageOptions: [25, 30, 50, 100]
    },
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      showColumnFilters: false,
      columnPinning: { left: ['view'] }
    },

    renderTopToolbar: ({ table }) => {
      return (
        <Box
          display="flex"
          gap="0.5rem"
          p="1rem 8px"
          flexShrink={0}
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" gap="0.5rem" alignItems="center">
            <DataTableTitle title={tableTitle} preTitle={tablePreTitle} />
          </Box>
          <Box display="flex" gap="0.5rem" alignItems="center">
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleGlobalFilterButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>
        </Box>
      );
    }
  });
  return (
    <Box flexGrow={1} display="flex" position="relative" flexDirection="column" overflow="hidden">
      <Box
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        overflow="hidden"
        position="absolute"
        flexDirection="column">
        <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </Box>
  );
};
