import React from 'react';
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { Box, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

type NotFoundProps = {
  title?: string;
  subtitle?: string;
};

export const ResourceNotFound: React.FC<NotFoundProps> = ({ title, subtitle }) => {
  return (
    <Box
      gap={2}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center">
      <Box color={red[900]} sx={{ '& svg': { fontSize: '60px' } }}>
        <RunningWithErrorsIcon />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          lineHeight={1}
          fontWeight={600}
          component="span"
          textAlign="center"
          fontSize="13px">
          {title ?? 'The requested resource was not found'}
        </Typography>
        <Typography lineHeight={1} component="span" fontSize="0.75rem" textAlign="center">
          {subtitle ?? 'Kindly make another request or go back to the home page'}
        </Typography>
      </Box>

      <Box
        to="/"
        display="flex"
        component={Link}
        alignItems="center"
        justifyContent="center"
        sx={{
          whiteSpace: 'nowrap',
          padding: '0.5625rem 1rem',
          textDecoration: 'none',
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '1',
          color: '#fcfdfe',
          textAlign: 'center',
          verticalAlign: 'middle',
          cursor: 'pointer',
          background: 'rgb(46, 85, 165)',
          borderRadius: '4px',
          border: '1px solid transparent',
          boxShadow: ' 0 1px 1px rgba(24,36,51, 0.06)',
          transition:
            'color .15s ease-in-out,background .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
          '&:hover': {
            color: '#fcfdfe',
            textDecoration: 'none',
            backgroundColor: 'rgba(46, 85, 165, .8)',
            borderColor: 'transparent'
          }
        }}>
        <ArrowBackIcon />
        <Box component="span" sx={{ margin: '0 calc(1rem/ -4) 0 calc(0.5625rem/ 2)' }}>
          Take me home
        </Box>
      </Box>
    </Box>
  );
};
