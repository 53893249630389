import React from 'react';

import { useAuth } from '@crf/ui/auth';
import { useDataQuery } from '@crf/ui/common';
import { TCrf } from '@crf/ui/crf/interfaces';

type TUseCrfsProps = {
  subject?: 'me' | 'all';
  query: 'all' | 'pending' | 'approved' | 'delaying';
};

type TUseCrfsReturn = {
  data: TCrf[] | null;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  isRefetching: boolean;
  refetch: () => void;
};

/**
 * @public
 */

export const useCrfs = ({ query, subject }: TUseCrfsProps): TUseCrfsReturn => {
  const { user } = useAuth();

  const url = React.useMemo(() => {
    let url = `/api/crfs?query=${query}`;

    if (user?.email && subject === 'me') url += `&email=${user.email}`;
    if ((user?.is_admin || user?.is_procurement) && subject === 'all')
      url += `&is_procurement=${true}`;

    return url;
  }, [user, query, subject]);

  const { data, isLoading, isError, error, isRefetching, refetch } = useDataQuery<TCrf[]>({ url });

  return {
    data,
    isLoading,
    isError,
    error,
    isRefetching,
    refetch
  };
};
