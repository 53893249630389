import React from 'react';

import { Box } from '@mui/material';

import { TabHeader } from './TabHeader';
import { TopBar } from './TopBar';

type TLayoutProps = {
  children: React.ReactNode;
  overflowX?: 'clip' | 'hidden' | 'auto' | 'scroll' | 'visible' | undefined;
  overflowY?: 'clip' | 'hidden' | 'auto' | 'scroll' | 'visible' | undefined;
};

/**
 * @public
 */

export const Layout: React.FC<TLayoutProps> = ({ children, overflowX, overflowY }) => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
      <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
        <Box
          top={0}
          zIndex={1020}
          flexShrink={0}
          display="flex"
          position="sticky"
          flexDirection="column"
        >
          <TopBar />
          <TabHeader />
        </Box>
        <Box
          component="main"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          sx={{
            overflowX: overflowX || 'hidden',
            overflowY: overflowY || 'hidden'
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
