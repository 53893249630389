import React from 'react';

import { Button } from '@mui/material';

type ActionButtonProps = {
  label: string;
  color: 'success' | 'error' | 'warning' | 'info';
  onClick?: () => void;
};

export const ActionButton: React.FC<ActionButtonProps> = ({ label, color, onClick }) => {
  return (
    <Button
      variant="contained"
      color={color}
      onClick={onClick}
      sx={{
        mx: 0.5,
        minWidth: { xs: '100%', sm: '32px' },
        padding: '4px 8px',
        fontSize: '0.6875rem',
        textTransform: 'capitalize'
      }}
    >
      {label}
    </Button>
  );
};
