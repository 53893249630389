import React from 'react';

type TUseUserNameInitialsProps = {
  name: string | null | undefined;
};

type TUseUserNameInitialsReturn = {
  initials: string;
};

export const useUserNameInitials = ({
  name
}: TUseUserNameInitialsProps): TUseUserNameInitialsReturn => {
  const initials: string = React.useMemo(() => {
    if (name === null || name === undefined || name.trim() === '') return 'JD';
    const nameArray = name.split(' ');
    if (nameArray.length === 1) {
      return nameArray[0].trim().charAt(0).toUpperCase();
    }
    return (
      nameArray[0].trim().charAt(0).toUpperCase() +
      nameArray[nameArray.length - 1].trim().charAt(0).toUpperCase()
    );
  }, [name]);

  return { initials };
};
