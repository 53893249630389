import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { axiosHttp } from '@crf/ui/api/axios';
import { TAxiosPostProps } from '@crf/ui/api/interfaces';

export const useReactQueryMutation = <T, Response = T>(
  url: TAxiosPostProps<Response>['url'],
  options: UseMutationOptions<T, Error, T | unknown> = {}
) => {
  return useMutation({
    ...options,
    mutationFn: (data: any = {}) => axiosHttp.post<T>({ data, url })
  });
};
