import { AxiosError } from 'axios';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { THttpResponse, useReactQuery } from '@crf/ui/api';
import { AuthPath, AuthReducerActionType, useAuth } from '@crf/ui/auth';

type TUseDataQueryProps = {
  url: string;
};

type TUseDataQueryReturn<T> = {
  data: T | null;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  isRefetching: boolean;
  refetch: () => void;
  status: 'pending' | 'error' | 'success';
  isFetching: boolean;
};

export const useDataQuery = <T>({ url }: TUseDataQueryProps): TUseDataQueryReturn<T> => {
  const navigate = useNavigate();
  const { authDispatch } = useAuth();

  const { pathname } = useLocation();
  const redirect = pathname === AuthPath.LOGIN ? AuthPath.HOME : pathname;

  const [isError, setIsError] = React.useState(false);

  const {
    data,
    error,
    isError: isQueryError,
    isLoading,
    isRefetching,
    refetch,
    status,
    isFetching
  } = useReactQuery<THttpResponse<T>>({
    url,
    options: { queryKey: [url] } // Pass the URL as queryKey
  });

  // Handle error scenarios
  React.useEffect(() => {
    if (isQueryError || error !== null || data instanceof AxiosError) {
      setIsError(true);

      // Example handling for unauthorized access
      if (data instanceof AxiosError && data.response?.status === 401) {
        authDispatch({
          redirect,
          type: AuthReducerActionType.SIGN_OUT
        });
        navigate(AuthPath.LOGIN, { replace: true });
      }
    }
  }, [isQueryError, error, data, authDispatch, navigate, redirect]);

  return {
    data: data?.data || null,
    isLoading,
    isError,
    error,
    isRefetching,
    refetch,
    status,
    isFetching
  };
};
