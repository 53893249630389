import { useFormik } from 'formik';
import React from 'react';

import { Box, FormControl, FormHelperText } from '@mui/material';

import { DialogBox, StyledTextarea } from '@crf/ui/common';
import { TRejectCrfPayload } from '@crf/ui/crf/interfaces';

import { ActionButton } from './ActionButton';

type RejectCrfDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  crf_id: string | number;
  handleCrfRejection: (payload: TRejectCrfPayload, resetForm: any) => void;
};

export const RejectCrfDialog: React.FC<RejectCrfDialogProps> = ({
  isOpen,
  crf_id,
  handleClose,
  handleCrfRejection
}) => {
  const formValidationSchema = (values: any) => {
    const errors = {} as any;
    if (!values.reason) {
      errors.reason = 'A reason is for rejection required';
    }
    return errors;
  };

  const rejectCrfForm = useFormik({
    initialValues: { reason: '' },
    validate: formValidationSchema,
    onSubmit: (values, { resetForm }) => {
      let payload: TRejectCrfPayload = {
        comment: values.reason
      };
      handleCrfRejection(payload, resetForm);
    }
  });

  return (
    <DialogBox
      open={isOpen}
      scroll="paper"
      showDialogTitle
      dialogTitle={`Rejecting CRF ${crf_id}`}
      handleClose={handleClose}
      dialogActions={
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          <ActionButton label="Cancel" color="success" onClick={handleClose} />
          <ActionButton label="Reject" color="error" onClick={rejectCrfForm.submitForm} />
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <FormControl sx={{ flexGrow: 1, width: '100%' }}>
          <StyledTextarea
            id="reason"
            aria-describedby="reason-text"
            name="reason"
            placeholder="Enter your reason for rejecting the CRF"
            maxRows={4}
            minRows={4}
            onChange={rejectCrfForm.handleChange}
            value={rejectCrfForm.values.reason}
          />
          {rejectCrfForm.errors.reason && (
            <FormHelperText id="reason-text">{rejectCrfForm.errors.reason}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </DialogBox>
  );
};
