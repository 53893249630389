import React from 'react';

import {
  GlobalReducerActionType,
  TCrfApprover,
  useDataQuery,
  useGlobalState
} from '@crf/ui/common';

type TUseCrfApproversReturn = {
  data: TCrfApprover[] | null | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  isRefetching: boolean;
  refetch: () => void;
  status: 'pending' | 'error' | 'success';
  isFetching: boolean;
};

/**
 * @public
 */

export const useCrfApprovers = (): TUseCrfApproversReturn => {
  const { crfApprovers, globalDispatch } = useGlobalState();

  const [approvers, setApprovers] = React.useState<TCrfApprover[]>([] as TCrfApprover[]);

  React.useEffect(() => {
    if (crfApprovers && crfApprovers.length > 0) {
      setApprovers(crfApprovers);
    }
  }, [crfApprovers]);

  const url = `/api/approvals?limit=200&skip=0`;

  const { data, isLoading, isError, error, isRefetching, refetch, status, isFetching } =
    useDataQuery<TCrfApprover[]>({
      url
    });

  const fetchedApprovers: TCrfApprover[] | undefined = React.useMemo(() => {
    if (!isError && !isLoading && !isRefetching && error === null && data) {
      if (Array.isArray(data) && data.length > 0) {
        return data as TCrfApprover[];
      }
    }
    return undefined;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (fetchedApprovers !== undefined && fetchedApprovers.length > 0) {
      globalDispatch({
        type: GlobalReducerActionType.CRF_APPROVERS,
        crfApprovers: fetchedApprovers
      });
    }
  }, [fetchedApprovers, isError, error, data, globalDispatch]);

  return {
    data: approvers,
    isError,
    isRefetching,
    error,
    status,
    refetch,
    isFetching,
    isLoading
  };
};
