import React from 'react';

import { Box, Typography } from '@mui/material';

type TDataTableTitleProps = {
  title: string;
  preTitle: string;
};

export const DataTableTitle: React.FC<TDataTableTitleProps> = ({ title, preTitle }) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Typography
          fontSize=".625rem"
          fontWeight={600}
          textTransform="uppercase"
          letterSpacing=".04em"
          lineHeight={1}
          color="#667382"
        >
          {preTitle}
        </Typography>
        <Typography
          fontSize=".875rem"
          fontWeight={600}
          textTransform="uppercase"
          letterSpacing=".04em"
          lineHeight={1}
          color="inherit"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
