/**
 * @public
 */

export enum AuthReducerActionType {
  SIGN_IN = 'SIGN_IN',
  REDIRECT = 'REDIRECT',
  SIGN_OUT = 'SIGN_OUT',
  LOGGING_OUT = 'LOGGING_OUT',
  AUTHENTICATING = 'AUTHENTICATING'
}

/**
 * @public
 */

export enum UserProfileType {
  CONNECTIVITY = 'connectivity',
  OADC = 'oadc'
}

/**
 * @public
 */

export enum AuthPath {
  HOME = '/crfs/mine',
  LOGIN = '/login',
  LOGOUT = '/logout'
}

/**
 * @public
 */
export enum AuthStorageKey {
  AUTH_STATE = 'LOGIN_INFO'
}
