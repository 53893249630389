import React from 'react';

import { Box, Link, Typography } from '@mui/material';

import { netsuiteCRFBrowserUrl } from '@crf/ui/crf/constants';
import { TCrf } from '@crf/ui/crf/interfaces';

type TPageHeaderProps = {
  crf: TCrf | undefined;
};

export const PageHeader: React.FC<TPageHeaderProps> = ({ crf }) => {
  return (
    <React.Fragment>
      {crf && (
        <Box
          pt="2rem"
          pb="1rem"
          mb="1.5rem"
          display="flex"
          flexShrink={0}
          flexWrap="wrap"
          minHeight="2.25rem"
          flexDirection="column"
        >
          <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box flexGrow={1}>
              <Typography
                fontSize=".625rem"
                fontWeight={600}
                textTransform="uppercase"
                letterSpacing=".04em"
                lineHeight="1rem"
                color="#667382"
              >
                CRF {crf.internal_id}
              </Typography>
              <Typography
                component="h2"
                margin={0}
                fontSize="1rem"
                lineHeight="1rem"
                fontWeight={600}
                color="inherit"
                display="flex"
                alignItems="center"
              >
                {crf.crf}
              </Typography>
            </Box>
            <Box
              flexShrink={0}
              marginTop={{
                xs: 2,
                sm: 0
              }}
            >
              {crf.internal_id && (
                <Link
                  href={`${netsuiteCRFBrowserUrl}&id=${crf.internal_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    lineHeight: '1',
                    fontWeight: 600,
                    color: '#0036a0',
                    fontSize: '.75rem',
                    letterSpacing: '.04em',
                    textDecoration: 'underline',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  View on NetSuite
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
