import { useFormik } from 'formik';
import React from 'react';

import { Box, FormControl, FormHelperText } from '@mui/material';

import { DialogBox, StyledTextarea } from '@crf/ui/common';
import { TQuestionCrfPayload } from '@crf/ui/crf/interfaces';

import { ActionButton } from './ActionButton';

type QuestionCrfDialogProps = {
  isOpen: boolean;
  crf_id: string | number;
  handleClose: () => void;
  handleQuestionCrf: (payload: TQuestionCrfPayload, resetForm: any) => void;
};

export const QuestionCrfDialog: React.FC<QuestionCrfDialogProps> = ({
  isOpen,
  crf_id,
  handleClose,
  handleQuestionCrf
}) => {
  const validationSchema = (values: any) => {
    const errors = {} as any;
    if (!values.question) {
      errors.question = 'A reason for questioning is required';
    }
    return errors;
  };

  const questionCrfForm = useFormik({
    initialValues: { question: '' },
    validate: validationSchema,
    onSubmit: (values, { resetForm }) => {
      let payload: TQuestionCrfPayload = {
        question: values.question
      };
      handleQuestionCrf(payload, resetForm);
    }
  });

  return (
    <DialogBox
      open={isOpen}
      scroll="paper"
      showDialogTitle
      handleClose={handleClose}
      dialogTitle={`Question CRF ${crf_id}`}
      dialogActions={
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          <ActionButton label="Cancel" color="success" onClick={handleClose} />
          <ActionButton label="Question" color="warning" onClick={questionCrfForm.submitForm} />
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <FormControl sx={{ flexGrow: 1, width: '100%' }}>
          <StyledTextarea
            id="question"
            aria-describedby="question-text"
            name="question"
            placeholder="Enter your reason for questioning the CRF"
            maxRows={4}
            minRows={4}
            onChange={questionCrfForm.handleChange}
            value={questionCrfForm.values.question}
          />
          {questionCrfForm.errors.question && (
            <FormHelperText id="question-text">{questionCrfForm.errors.question}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </DialogBox>
  );
};
