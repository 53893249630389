import { AxiosError } from 'axios';
import React from 'react';
import { NavLink } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import { common, green, grey } from '@mui/material/colors';

import { THttpResponse, axiosHttp } from '@crf/ui/api';
import { OadcLogo } from '@crf/ui/assets';
import { useAuth } from '@crf/ui/auth';
import { TCrfApprover, useUserNameInitials } from '@crf/ui/common';
import { crfApproversSyncError } from '@crf/ui/crf';
import { HttpDialog } from '@crf/ui/crf/components';

import { SidebarDrawer } from './SidebarDrawer';

export const TopBar: React.FC = () => {
  const { user, logoutUser } = useAuth();
  const { initials } = useUserNameInitials({ name: user?.name });

  const [userMenuAnchor, setUserMenuAnchor] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(event.currentTarget);
  };
  const handleCloseUserMenu = (_event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(null);
  };

  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsOpenDrawer(newOpen);
  };

  const [processingHttpDialogMessage, setProcessingHttpDialogMessage] =
    React.useState<string>('Processing request...');
  const timer = React.useRef<ReturnType<typeof setTimeout>>();
  const [isProcessingHttp, setIsProcessingHttp] = React.useState<boolean>(false);
  const [isHttpSuccess, setIsHttpSuccess] = React.useState<boolean>(false);
  const [isHttpError, setIsHttpError] = React.useState<boolean>(false);

  const [isOpenProcessingHttpDialog, setIsOpenProcessingHttpDialog] =
    React.useState<boolean>(false);
  const handleOpenProcessingHttpDialog = () => {
    setIsOpenProcessingHttpDialog(true);
  };
  const handleCloseProcessingHttpDialog = (_event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    setIsOpenProcessingHttpDialog(false);
  };

  const syncApprovers = (): void => {
    void (async (): Promise<void> => {
      setIsProcessingHttp(true);
      handleOpenProcessingHttpDialog();

      let error: Error | null = null;

      try {
        const response = await axiosHttp.post<
          THttpResponse<Array<TCrfApprover>> | AxiosError | undefined
        >({
          url: 'api/sync/approvers'
        });

        if (
          !response ||
          response instanceof String ||
          response instanceof AxiosError ||
          response instanceof Error
        ) {
          error = new Error(crfApproversSyncError);
          timer.current = setTimeout(() => {
            setProcessingHttpDialogMessage(error?.message || '');
            setIsProcessingHttp(false);
            setIsHttpSuccess(false);
            setIsHttpError(true);
          }, 2000);
          timer.current = setTimeout(() => {
            handleCloseProcessingHttpDialog();
          }, 5000);
        } else {
          timer.current = setTimeout(() => {
            setProcessingHttpDialogMessage('Synced CRF approvers successfully');
            setIsProcessingHttp(false);
            setIsHttpSuccess(true);
            setIsHttpError(false);
          }, 2000);
          timer.current = setTimeout(() => {
            handleCloseProcessingHttpDialog();
          }, 5000);
        }
      } catch (error) {
        console.log('Sync Approvers error', { error });
        const err = new Error(crfApproversSyncError);
        timer.current = setTimeout(() => {
          setProcessingHttpDialogMessage(err?.message || '');
          setIsProcessingHttp(false);
          setIsHttpSuccess(false);
          setIsHttpError(true);
        }, 2000);
        timer.current = setTimeout(() => {
          handleCloseProcessingHttpDialog();
        }, 5000);
      }
    })();
  };

  return (
    <React.Fragment>
      <AppBar
        position="relative"
        sx={{
          color: '#182433',
          maxHeight: '3rem',
          minHeight: '3rem',
          background: '#ffffff',
          boxShadow: { xs: 1, md: 'inset 0 calc(-1 * 1px) 0 0 #dadfe5' }
        }}>
        <Container maxWidth="lg">
          <Toolbar
            variant="dense"
            sx={{
              maxHeight: '3rem',
              minHeight: '3rem',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0px !important'
            }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              sx={{
                width: '32px',
                height: '32px',
                padding: '2px',
                bgcolor: grey[100],
                alignItems: 'center',
                justifyContent: 'center',
                display: { xs: 'flex', md: 'none' },
                transition: 'background-color .3s, color .3s',
                '&:hover': { bgcolor: green[600], color: common.white }
              }}>
              <MenuIcon />
            </IconButton>
            <Box
              to="/"
              gap=".5rem"
              display="flex"
              color="inherit"
              alignItems="center"
              component={NavLink}
              sx={{
                textDecoration: 'none'
              }}>
              <Box width="6rem" component="img" alt="OADC Logo" src={OadcLogo} srcSet={OadcLogo} />
              <Typography
                noWrap
                variant="h6"
                fontSize="13px"
                color="inherit"
                fontWeight={600}
                sx={{
                  display: { xs: 'none', md: 'block' },
                  textDecoration: 'none'
                }}>
                CRF Approval
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" color="inherit">
              <Button
                onClick={handleOpenUserMenu}
                sx={{
                  gap: '.5rem',
                  height: '2.5rem',
                  display: 'flex',
                  color: 'inherit',
                  alignItems: 'center',
                  flexDirection: 'row',
                  textTransform: 'none',
                  padding: '0.25rem',
                  minWidth: 'auto'
                }}>
                <Box
                  color="#fff"
                  width="2rem"
                  height="2rem"
                  display="flex"
                  textAlign="center"
                  borderRadius="4px"
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                  textTransform="uppercase"
                  sx={{
                    userSelect: 'none',
                    verticalAlign: 'bottom',
                    background: '#0c349c no-repeat center/cover',
                    boxShadow: 'inset 0 0 0 1px #0c349c'
                  }}>
                  <Typography fontSize="0.875rem" lineHeight={1} fontWeight={500}>
                    {initials}
                  </Typography>
                </Box>
                <Box
                  gap=".25rem"
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="start"
                  sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Typography lineHeight={1} fontSize="13px">
                    {user?.name}
                  </Typography>
                  <Typography lineHeight={1} fontSize="0.75rem">
                    {user?.email}
                  </Typography>
                </Box>
              </Button>
              <Menu
                keepMounted
                anchorEl={userMenuAnchor}
                onClose={handleCloseUserMenu}
                open={Boolean(userMenuAnchor)}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={{
                  mt: '1.25rem',
                  padding: '0px',
                  minWidth: '15rem',
                  '& .MuiMenu-list': {
                    padding: '0px'
                  }
                }}
                PaperProps={{
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    boxShadow: 'none',
                    mt: 1.5,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}>
                <MenuItem
                  key="email"
                  onClick={handleCloseUserMenu}
                  sx={{
                    minHeight: '2rem',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '.25rem'
                  }}>
                  <Typography lineHeight={1} fontSize="13px">
                    {user?.name}
                  </Typography>
                  <Typography lineHeight={1} fontSize="0.75rem">
                    {user?.email}
                  </Typography>
                </MenuItem>
                {user?.is_admin && (
                  <>
                    <Divider sx={{ margin: '0 !important' }} />
                    <MenuItem sx={{ minHeight: '3rem', py: 1 }} onClick={() => syncApprovers()}>
                      <Typography lineHeight={1} fontSize="0.875rem">
                        Sync Approvers
                      </Typography>
                    </MenuItem>
                  </>
                )}
                <Divider sx={{ margin: '0 !important' }} />
                <MenuItem sx={{ minHeight: '3rem', py: 1 }} onClick={() => logoutUser()}>
                  <Typography lineHeight={1} fontSize="0.875rem">
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SidebarDrawer open={isOpenDrawer} toggleDrawer={toggleDrawer} />

      <HttpDialog
        isError={isHttpError}
        isSuccess={isHttpSuccess}
        isProcessing={isProcessingHttp}
        isOpen={isOpenProcessingHttpDialog}
        handleClose={handleCloseProcessingHttpDialog}
        dialogMessage={processingHttpDialogMessage}
      />
    </React.Fragment>
  );
};
