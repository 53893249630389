import React from 'react';
import { NavLink } from 'react-router-dom';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, Drawer, List } from '@mui/material';
import { common, grey, red } from '@mui/material/colors';

import { OadcLogo } from '@crf/ui/assets';
import { useAuth } from '@crf/ui/auth';

import { SidebarDrawerItem } from './SidebarDrawerItem';

type SidebarDrawerProps = {
  open: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

export const SidebarDrawer: React.FC<SidebarDrawerProps> = ({ open, toggleDrawer }) => {
  const { user } = useAuth();

  return (
    <Drawer open={open} onClose={toggleDrawer(false)}>
      <Box
        flexGrow={1}
        display="flex"
        role="presentation"
        sx={{ width: 250 }}
        flexDirection="column"
        onClick={toggleDrawer(false)}>
        <Box
          px={1}
          py={1}
          flexShrink={0}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ maxHeight: '3rem', minHeight: '3rem' }}>
          <Box
            to="/"
            gap=".5rem"
            display="flex"
            color="inherit"
            alignItems="center"
            component={NavLink}
            sx={{
              textDecoration: 'none'
            }}>
            <Box width="6rem" component="img" alt="OADC Logo" src={OadcLogo} srcSet={OadcLogo} />
          </Box>
          <Box>
            <Button
              aria-label="close drawer"
              sx={{
                width: '32px',
                height: '32px',
                padding: '2px',
                display: 'flex',
                color: grey[700],
                maxWidth: '32px',
                minWidth: '32px',
                maxHeight: '32px',
                minHeight: '32px',
                bgcolor: grey[200],
                borderRadius: '50%',
                alignItems: 'center',
                transition: 'background-color .3s, color .3s',
                '&:hover': {
                  bgcolor: red[500],
                  color: common.white
                },
                '& svg': {
                  fontSize: 20
                }
              }}
              onClick={toggleDrawer(false)}>
              <CloseIcon />
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
          {user?.is_approver && (
            <List
              sx={{
                gap: 1,
                padding: 1,
                marginLeft: 0,
                marginRight: 0,
                display: 'flex',
                flexDirection: 'column'
              }}>
              <SidebarDrawerItem path="/crfs/mine" label="My CRFs" icon={<AssignmentIndIcon />} />
              <SidebarDrawerItem
                path="/crfs/pending-my-approval"
                label="Pending My Approval"
                icon={<AssignmentReturnedIcon />}
              />
              <SidebarDrawerItem
                label="Approved By Me"
                path="/crfs/approved-by-me"
                icon={<AssignmentTurnedInIcon />}
              />
            </List>
          )}

          {(user?.is_admin || user?.is_procurement) && (
            <React.Fragment>
              <Divider />
              <List
                sx={{
                  gap: 1,
                  padding: 1,
                  marginLeft: 0,
                  marginRight: 0,
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <SidebarDrawerItem path="/crfs" label="All CRFs" icon={<AssignmentIcon />} />
                <SidebarDrawerItem
                  path="/crfs/pending"
                  label="All Pending"
                  icon={<AssignmentReturnedIcon />}
                />
                <SidebarDrawerItem
                  label="All Approved"
                  path="/crfs/approved"
                  icon={<AssignmentTurnedInIcon />}
                />
                <SidebarDrawerItem
                  path="/crfs/delaying"
                  label="All Delaying"
                  icon={<AssignmentLateIcon />}
                />
              </List>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};
