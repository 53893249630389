import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { ResourceNotFound } from '@crf/ui/common/components';

const CRF = React.lazy(() => import('@crf/ui/crf/pages/CRF'));
const CRFs = React.lazy(() => import('@crf/ui/crf/pages/CRFs'));
const Login = React.lazy(() => import('@crf/ui/auth/pages/Login'));
const MyCRFs = React.lazy(() => import('@crf/ui/crf/pages/MyCRFs'));
const PendingCRFs = React.lazy(() => import('@crf/ui/crf/pages/PendingCRFs'));
const CRFsPendingMyApproval = React.lazy(() => import('@crf/ui/crf/pages/PendingMyApproval'));
const CRFsApprovedByMe = React.lazy(() => import('@crf/ui/crf/pages/ApprovedByMe'));
const ApprovedCRFs = React.lazy(() => import('@crf/ui/crf/pages/ApprovedCRFs'));
const DelayingCRFs = React.lazy(() => import('@crf/ui/crf/pages/DelayingCRFs'));

export const routing = createBrowserRouter([
  { path: '/', element: <Navigate to="/crfs" /> },
  { path: '/login', element: <Login /> },
  { path: '/crfs', element: <CRFs /> },
  { path: '/crfs/:id', element: <CRF /> },
  { path: '/crfs/pending', element: <PendingCRFs /> },
  { path: '/crfs/pending/:id', element: <CRF /> },
  { path: '/crfs/approved', element: <ApprovedCRFs /> },
  { path: '/crfs/approved/:id', element: <CRF /> },
  { path: '/crfs/delaying', element: <DelayingCRFs /> },
  { path: '/crfs/delaying/:id', element: <CRF /> },
  { path: '/crfs/mine', element: <MyCRFs /> },
  { path: '/crfs/mine/:id', element: <CRF /> },
  { path: '/crfs/pending-my-approval', element: <CRFsPendingMyApproval /> },
  { path: '/crfs/pending-my-approval/:id', element: <CRF /> },
  { path: '/crfs/approved-by-me', element: <CRFsApprovedByMe /> },
  { path: '/crfs/approved-by-me/:id', element: <CRF /> },
  { path: '*', element: <ResourceNotFound /> }
]);
