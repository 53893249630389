import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import {
    TAxiosGetProps,
    TAxiosHttpConfig,
    TAxiosPostProps,
    TAxiosResponse
} from '@crf/ui/api/interfaces';

const baseUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 35000,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  },
  withCredentials: true
});

const getAccessToken = () => {
  if (document.cookie === '') {
    return null;
  }
  return (
    document.cookie
      ?.split('; ')
      .find((row) => row.startsWith('csrf_access_token='))
      ?.split('=')[1] ?? null
  );
};

const request = async (props: TAxiosHttpConfig) => {
  try {
    const axiosRequestConfig: AxiosRequestConfig = props;
    if (document.cookie !== '') {
      if (axiosRequestConfig.headers === null) {
        axiosRequestConfig.headers = {};
      }
      axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = getAccessToken();
    }
    return (await axiosInstance.request(axiosRequestConfig)).data;
  } catch (error: unknown) {
    return error as AxiosError;
  }
};

export const axiosHttp = {
  get: async <ResponseRecord>(props: TAxiosGetProps): Promise<ResponseRecord> => {
    return await request({ ...props, method: 'get' });
  },
  post: async <T, Data extends TAxiosResponse = TAxiosResponse>(
    props: TAxiosPostProps<Data>
  ): Promise<T> => {
    return await request({ ...props, method: 'post' });
  },
  http: async <T>(props: TAxiosHttpConfig): Promise<T> => {
    return await ((await request({ ...props })) as Promise<T>);
  }
};
