import React from 'react';

import { GlobalReducerActionType } from '@crf/ui/common/enums';
import { useGlobalStateAndDispatch } from '@crf/ui/common/hooks';
import { TGlobalReducerAction, TGlobalState } from '@crf/ui/common/interfaces';

/**
 * @public
 */

export interface TGlobalContextProps extends TGlobalState {
  globalDispatch: React.Dispatch<TGlobalReducerAction>;
}

/**
 * @public
 */

export const GlobalContext = React.createContext<TGlobalContextProps | undefined>(undefined);

GlobalContext.displayName = 'GlobalContext';

/**
 * @public
 */

export type TGlobalStateProviderProps = {
  children?: React.ReactNode;
};

/**
 * @public
 */

export const GlobalStateProvider = (props: TGlobalStateProviderProps): JSX.Element => {
  const hasMounted = React.useRef(false);
  const { globalState, globalDispatch } = useGlobalStateAndDispatch();

  React.useEffect(() => {
    if (hasMounted.current) {
      return;
    }
    hasMounted.current = true;
  }, [globalState]);

  return (
    <GlobalContext.Provider value={{ ...globalState, globalDispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

/**
 * @public
 */

export const GlobalReducer = (state: TGlobalState, action: TGlobalReducerAction): TGlobalState => {
  switch (action.type) {
    case GlobalReducerActionType.CRF_APPROVERS:
      const approversState = {
        ...state,
        crfApprovers: action.crfApprovers,
        alter: action.alter
      };
      localStorage.setItem('globalState', JSON.stringify(approversState));
      return approversState;
    default:
      const defaultState = { ...state };
      localStorage.setItem('globalState', JSON.stringify(defaultState));
      return defaultState;
  }
};
