import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, axiosHttp } from '@crf/ui/api';
import { userLogoutError } from '@crf/ui/auth/constants';
import { AuthReducerActionType, AuthStorageKey } from '@crf/ui/auth/enums';
import { useAuthStateAndDispatch } from '@crf/ui/auth/hooks';
import {
  TAuthReducerAction,
  TAuthState,
  TAuthUser,
  TLoginUserArgs,
  TLogoutUserArgs
} from '@crf/ui/auth/interfaces';
import { clearCookies, signOutError } from '@crf/ui/auth/utils';

/**
 * @public
 */

export interface TAuthContextProps extends TAuthState {
  logoutUser(args?: TLogoutUserArgs): void;
  loginUser(args?: TLoginUserArgs): void;
  authDispatch: React.Dispatch<TAuthReducerAction>;
}

/**
 * @public
 */

export const AuthContext = React.createContext<TAuthContextProps | undefined>(undefined);

AuthContext.displayName = 'AuthContext';

/**
 * @public
 */

export type TAuthProviderProps = {
  children?: React.ReactNode;
};

/**
 * @public
 */

export const AuthProvider = (props: TAuthProviderProps): JSX.Element => {
  const hasMounted = React.useRef(false);
  const { authState, authDispatch } = useAuthStateAndDispatch();

  const loginUser = (_args: TLoginUserArgs): void => {
    void (async (): Promise<void> => {
      let error: Error | null = null;
      try {
        // Attempt to perform a GET request using axiosHttp
        const response = await axiosHttp.get<THttpResponse<TAuthUser> | AxiosError | undefined>({
          url: 'auth/user'
        });
        if (
          !response ||
          response instanceof String ||
          response instanceof AxiosError ||
          response instanceof Error
        ) {
          // Handle case where response is falsy or an error occurred
          error = new Error(userLogoutError);
          authDispatch({
            type: AuthReducerActionType.SIGN_OUT,
            error: signOutError(error),
            redirect: authState.redirect
          });
          clearCookies();
        } else {
          if (response.data && response.data.id) {
            // Dispatch SIGN_IN action if response contains user data with an ID
            authDispatch({
              type: AuthReducerActionType.SIGN_IN,
              user: response.data,
              redirect: authState.redirect
            });
          } else {
            // Handle case where response does not contain valid user data
            authDispatch({
              type: AuthReducerActionType.SIGN_OUT,
              error: signOutError(error),
              redirect: authState.redirect
            });
            clearCookies();
          }
        }
      } catch (err: unknown) {
        // Catch any errors that occur during the GET request
        error = new Error(userLogoutError);
        authDispatch({
          type: AuthReducerActionType.SIGN_OUT,
          error: signOutError(error),
          redirect: authState.redirect
        });
        clearCookies();
      }
    })();
  };

  const logoutUser = (): void => {
    void (async (): Promise<void> => {
      authDispatch({
        type: AuthReducerActionType.REDIRECT,
        redirect: '/crfs/mine'
      });

      try {
        // Attempt to perform a POST request using axiosHttp
        const response = await axiosHttp.post<THttpResponse<null> | AxiosError>({
          url: 'auth/logout'
        });

        if (response === null) {
          // If response is null, trigger sign-out with no error
          authDispatch({
            type: AuthReducerActionType.SIGN_OUT,
            redirect: '/crfs/mine'
          });
        } else {
          // If there is a response (either success or error), handle sign-out with error
          authDispatch({
            type: AuthReducerActionType.SIGN_OUT,
            error: signOutError(new Error(userLogoutError)),
            redirect: '/crfs/mine'
          });
        }
      } catch (error) {
        // Catch any errors that occur during the HTTP request
        authDispatch({
          type: AuthReducerActionType.SIGN_OUT,
          error: signOutError(new Error(error as string)),
          redirect: '/crfs/mine'
        });
      }
    })();
  };

  React.useEffect(() => {
    if (hasMounted.current) {
      return;
    }
    hasMounted.current = true;
  }, [authState]);

  return (
    <AuthContext.Provider value={{ ...authState, logoutUser, loginUser, authDispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

/**
 * @public
 */

export const AuthReducer = (state: TAuthState, action: TAuthReducerAction): TAuthState => {
  switch (action.type) {
    case AuthReducerActionType.AUTHENTICATING:
      const loadingState = {
        ...state,
        isAuthenticating: action.isAuthenticating
      };
      localStorage.setItem(AuthStorageKey.AUTH_STATE, JSON.stringify(loadingState));
      return loadingState;
    case AuthReducerActionType.SIGN_IN:
      const loginSate = {
        ...state,
        error: undefined,
        user: action.user,
        isLoggingOut: false,
        isAuthenticated: true,
        isAuthenticating: false,
        redirect: action.redirect
      };
      localStorage.setItem(AuthStorageKey.AUTH_STATE, JSON.stringify(loginSate));
      return loginSate;
    case AuthReducerActionType.LOGGING_OUT:
      const loggingOutState = {
        ...state,
        redirect: undefined,
        isLoggingOut: action.isLoggingOut
      };
      localStorage.setItem(AuthStorageKey.AUTH_STATE, JSON.stringify(loggingOutState));
      return loggingOutState;
    case AuthReducerActionType.SIGN_OUT:
      const logoutState = {
        ...state,
        user: undefined,
        error: action.error,
        isLoggingOut: false,
        isAuthenticated: false,
        isAuthenticating: false,
        redirect: action.redirect
      };
      localStorage.setItem(AuthStorageKey.AUTH_STATE, JSON.stringify(logoutState));
      return logoutState;
    case AuthReducerActionType.REDIRECT:
      const redirectState = { ...state, redirect: action.redirect };
      localStorage.setItem(AuthStorageKey.AUTH_STATE, JSON.stringify(redirectState));
      return redirectState;
    default:
      const defaultState = { ...state };
      localStorage.setItem(AuthStorageKey.AUTH_STATE, JSON.stringify(defaultState));
      return defaultState;
  }
};
