/**
 * @public
 */

export const crfFetchError = 'Request failed. Could not fetch CRF';

/**
 * @public
 */

export const crfApproversFetchError = 'Request failed. Could not fetch CRF approvers list';

/**
 * @public
 */

export const crfApproversSyncError = 'Request failed. Could not sync CRF approvers list';

/**
 * @public
 */

export const netsuiteCRFBrowserUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://3398896.app.netsuite.com/app/common/custom/custrecordentry.nl?rectype=2529'
    : 'https://3398896-sb1.app.netsuite.com/app/common/custom/custrecordentry.nl?rectype=2529';
