import React from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { useAuth } from '@crf/ui/auth';
import { TCrfApprover, defaultColours, signatureFonts } from '@crf/ui/common';
import { TCrf } from '@crf/ui/crf/interfaces';

import { ActionButton } from './ActionButton';

type TApprovalProps = {
  crf: TCrf | undefined;
  handleCrfApproval: () => void;
  handleOpenRejectCrfDialog: () => void;
  handleOpenQuestionCrfDialog: () => void;
  crfApprovers: TCrfApprover[] | undefined;
};

const capitalise = (str: string) => {
  if (str.length === 0) return str; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const upperCaseCertainWords = (str: string) => {
  if (
    str.toLowerCase() === 'ceo' ||
    str.toLowerCase() === 'cfo' ||
    str.toLowerCase() === 'coo' ||
    str.toLowerCase() === 'cdo' ||
    str.toLowerCase() === 'cto' ||
    str.toLowerCase() === 'pmo'
  ) {
    return str.toUpperCase();
  }
  return capitalise(str);
};

const TableSection: React.FC<{
  level: string;
  levelName: string;
  levelApprovers: any[];
  crf: TCrf;
  signatureBox: (signature?: string) => JSX.Element | null;
  approvalActionArea?: (email?: string, levelName?: string) => JSX.Element | null;
}> = ({ level, levelName, levelApprovers, crf, signatureBox, approvalActionArea }) => {
  const { user } = useAuth();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <Box mb={3}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody
            sx={{
              '& .MuiTableCell-root': {
                fontWeight: 500,
                fontSize: '.8125rem',
                border: `1px solid ${grey[400]}`,
                borderTop: '0px !important',
                padding: isSmallScreen ? '4px 8px' : '8px'
              }
            }}>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  fontWeight: 700,
                  bgcolor: grey[300],
                  padding: isSmallScreen ? '4px 8px' : '8px'
                }
              }}>
              <TableCell align="left" width="2rem" sx={{ borderRight: `1px solid ${grey[400]}` }}>
                {level}
              </TableCell>
              <TableCell align="left" colSpan={isSmallScreen ? 2 : 3}>
                {`${levelName} Approval`}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  fontWeight: '700 !important',
                  padding: isSmallScreen ? '4px 8px' : '8px'
                }
              }}>
              <TableCell align="left" sx={{ width: isSmallScreen ? '20%' : '15%' }}>
                Name
              </TableCell>
              {!isSmallScreen && (
                <>
                  <TableCell align="left">Approver</TableCell>
                  <TableCell align="left">Signature</TableCell>
                  <TableCell align="left">Approval Date</TableCell>
                </>
              )}
              {isSmallScreen && <TableCell align="left">Approver Details</TableCell>}
            </TableRow>
            {levelApprovers.map((approver, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontWeight: '700 !important' }}>
                  {upperCaseCertainWords(approver.title)}
                </TableCell>
                {!isSmallScreen && (
                  <>
                    <TableCell>{crf[approver.key as keyof TCrf] || approver.name}</TableCell>
                    <TableCell>
                      {crf[approver.key as keyof TCrf]
                        ? signatureBox(crf[approver.key as keyof TCrf])
                        : approvalActionArea &&
                            user?.is_approver &&
                            user?.email.toLowerCase() === approver.email.toLowerCase()
                          ? approvalActionArea(approver.email, levelName)
                          : ''}
                    </TableCell>
                    <TableCell>{crf[approver.dateKey as keyof TCrf] || ''}</TableCell>
                  </>
                )}
                {isSmallScreen && (
                  <TableCell>
                    <Typography variant="body2" fontWeight={700}>
                      Approver:
                    </Typography>
                    <Typography variant="body2" py="0.5rem">
                      {crf[approver.key as keyof TCrf] || approver.name}
                    </Typography>
                    <Typography variant="body2" fontWeight={700}>
                      Signature:
                    </Typography>
                    <Box py="0.5rem">
                      {crf[approver.key as keyof TCrf]
                        ? signatureBox(crf[approver.key as keyof TCrf])
                        : approvalActionArea &&
                            user?.is_approver &&
                            user?.email.toLowerCase() === approver.email.toLowerCase()
                          ? approvalActionArea(approver.email, levelName)
                          : ''}
                    </Box>
                    <Typography variant="body2" fontWeight={700}>
                      Approval Date:
                    </Typography>
                    <Typography variant="body2" py="0.5rem">
                      {crf[approver.dateKey as keyof TCrf] || ''}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const Approvals: React.FC<TApprovalProps> = ({
  crf,
  crfApprovers,
  handleCrfApproval,
  handleOpenRejectCrfDialog,
  handleOpenQuestionCrfDialog
}) => {
  if (!crf || !crfApprovers) {
    return null;
  }

  const levelMap: Record<number, string> = {
    1: 'levelOne',
    2: 'levelTwo',
    3: 'levelThree'
  };

  // Convert the approver's level number to its corresponding level string
  const getLevelString = (level: number): string => {
    return levelMap[level] || 'unknownLevel';
  };

  const transformApproversList = (approversList: TCrfApprover[]) => {
    const approversByCrfType: Record<string, Record<string, any[]>> = {
      projects: { levelOne: [], levelTwo: [], levelThree: [] },
      operations: { levelOne: [], levelTwo: [], levelThree: [] }
    };

    approversList.forEach((approver) => {
      const levelKey = getLevelString(approver.level);

      const approverTitle = approver.name.replace('OADC CRF - ', '').trim().toLowerCase();

      // Create the approver object
      const approverObject = {
        key: `${approverTitle.toLowerCase()}_approved_by`,
        title: approverTitle,
        email: approver.primary_approver,
        name: approver.primary_approver_name,
        dateKey: `${approverTitle.toLowerCase()}_approval_date`
      };

      // Check if the approver type is "All"
      if (approver.type.toLowerCase() === 'all') {
        // Add the approver to all CRF types
        Object.keys(approversByCrfType).forEach((crfType) => {
          approversByCrfType[crfType][levelKey].push({
            ...approverObject
          });
        });
      } else {
        // Add the approver to specific CRF types they are responsible for
        approver.type.split(',').forEach((type) => {
          const cleanedType = type.trim().toLowerCase();
          if (approversByCrfType[cleanedType]) {
            approversByCrfType[cleanedType][levelKey].push({
              ...approverObject
            });
          }
        });
      }
    });

    return approversByCrfType;
  };

  const approversByCrfType = transformApproversList(crfApprovers);

  const levels = approversByCrfType[crf.crf_type.toLowerCase()] || {};

  console.log('levels', levels);

  const getNextApprovalLevel = () => {
    if (levels.levelOne) {
      for (let approver of levels.levelOne) {
        if (!crf[approver.key as keyof TCrf]) {
          return { level: 'Level One', approvers: levels.levelOne };
        }
      }
    }

    if (levels.levelTwo) {
      for (let approver of levels.levelTwo) {
        if (!crf[approver.key as keyof TCrf]) {
          return { level: 'Level Two', approvers: levels.levelTwo };
        }
      }
    }

    if (levels.levelThree) {
      for (let approver of levels.levelThree) {
        if (!crf[approver.key as keyof TCrf]) {
          return { level: 'Level Three', approvers: levels.levelThree };
        }
      }
    }

    return null;
  };

  const nextApproval = getNextApprovalLevel();

  const isLevelApproved = (levelApprovers: any[]) =>
    levelApprovers.every((approver: Record<string, any>) => crf[approver.key as keyof TCrf]);

  const signatureBox = (signature?: string) => {
    if (!signature) {
      return null;
    }
    return (
      <Box
        fontFamily={signatureFonts[0]}
        color={defaultColours[26]}
        fontSize="18px"
        fontWeight={400}>
        {signature}
      </Box>
    );
  };

  const approvalActionArea = (email?: string, levelName?: string) => {
    if (!email && !levelName) {
      return null;
    }

    return nextApproval && nextApproval.level === levelName ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'start', sm: 'center' },
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 1, sm: 0 }
        }}>
        <ActionButton label="Approve" color="success" onClick={handleCrfApproval} />
        <ActionButton label="Question" color="warning" onClick={handleOpenQuestionCrfDialog} />
        <ActionButton label="Reject" color="error" onClick={handleOpenRejectCrfDialog} />
      </Box>
    ) : null;
  };

  return (
    <Box marginBottom="1.5rem">
      <Typography
        component="h1"
        fontSize="1rem"
        lineHeight="1"
        fontWeight={700}
        textAlign="center"
        letterSpacing=".04em"
        marginBottom="1.5rem">
        Section 2: Authorisation
      </Typography>
      <TableSection
        level="2.1"
        levelApprovers={levels.levelOne || []}
        crf={crf}
        levelName="Level One"
        signatureBox={signatureBox}
        approvalActionArea={approvalActionArea}
      />
      <TableSection
        level="2.2"
        levelApprovers={levels.levelTwo || []}
        crf={crf}
        levelName="Level Two"
        signatureBox={signatureBox}
        approvalActionArea={isLevelApproved(levels.levelOne) ? approvalActionArea : undefined}
      />
      <TableSection
        level="2.3"
        levelApprovers={levels.levelThree || []}
        crf={crf}
        levelName="Level Three"
        signatureBox={signatureBox}
        approvalActionArea={isLevelApproved(levels.levelTwo) ? approvalActionArea : undefined}
      />
      <Box mb={3} height={50}></Box>
    </Box>
  );
};
