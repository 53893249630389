import styled from '@emotion/styled';

import { TextareaAutosize } from '@mui/material';

export const StyledTextarea = styled(TextareaAutosize)(
  () => `
    max-width: 100%;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: #24292f;
    background: #fff;
    border: 1px solid #d0d7de;
    box-shadow: 0px 2px 2px #f6f8fa;

    &:hover {
      border-color: #3399FF;
    }

    &:focus {
      border-color: #3399FF;
      box-shadow: 0 0 0 3px #b6daff;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
