import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { Box, ListItem } from '@mui/material';
import { green, grey } from '@mui/material/colors';

type TabHeaderItemProps = {
  label: string;
  path: string;
  icon: React.ReactNode;
};

export const TabHeaderItem: React.FC<TabHeaderItemProps> = ({ path, icon, label }) => {
  const { id } = useParams();
  const location = useLocation();
  const [isActive, setIsActive] = React.useState<string>('');

  React.useEffect(() => {
    if (id) {
      setIsActive(location.pathname.replace(`/${id}`, ''));
    } else {
      setIsActive(location.pathname);
    }
  }, [location, path, id]);

  return (
    <ListItem
      sx={{
        margin: 0,
        padding: 0,
        display: 'flex',
        width: 'max-content',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        transition: 'border .3s',
        '&:has(> a.active-link)': {
          '&:after': {
            content: '""',
            left: 0,
            right: 0,
            bottom: '0',
            position: 'absolute',
            borderBottom: `3px solid ${green[600]}`
          }
        }
      }}
    >
      <Box
        p={1}
        gap={1}
        to={path}
        border={0}
        flexGrow={1}
        display="flex"
        lineHeight="1"
        borderRadius={1}
        color={grey[600]}
        fontSize=".8125rem"
        component={NavLink}
        flexDirection="row"
        alignItems="center"
        sx={{
          height: '32px',
          maxHeight: '32px',
          minHeight: '32px',
          textDecoration: 'none',
          transition: 'color .3s, background-color .3s, font-weight .3s',
          '&:hover': {
            fontWeight: 600,
            color: grey[700],
            bgcolor: grey[200]
          },
          '&.active-link': {
            fontWeight: 600,
            color: green[600],
            bgcolor: 'transparent',
            '&:hover': {
              fontWeight: 600,
              color: green[600],
              bgcolor: grey[200]
            }
          }
        }}
        className={isActive === path ? 'active-link' : ''}
      >
        <Box
          display="flex"
          component="span"
          alignItems="center"
          sx={{
            '& svg': {
              fontSize: '20px'
            }
          }}
        >
          {icon}
        </Box>
        <Box component="span" display="flex" alignItems="center">
          {label}
        </Box>
      </Box>
    </ListItem>
  );
};
