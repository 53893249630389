import React from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { AppBar, Container, List, Toolbar } from '@mui/material';

import { useAuth } from '@crf/ui/auth';

import { TabHeaderItem } from './TabHeaderItem';

export const TabHeader: React.FC = () => {
  const { user } = useAuth();

  return (
    <AppBar
      position="relative"
      sx={{
        color: '#182433',
        maxHeight: '2.5rem',
        minHeight: '2.5rem',
        background: '#ffffff',
        display: { xs: 'none', md: 'block' },
        boxShadow: { md: 1 }
      }}>
      <Container maxWidth="lg">
        <Toolbar
          variant="dense"
          sx={{
            maxHeight: '2.5rem',
            minHeight: '2.5rem',
            alignItems: 'center',
            padding: '0px !important'
          }}>
          <List
            sx={{
              padding: 0,
              gap: '1rem',
              marginLeft: 0,
              marginRight: 0,
              display: 'flex',
              minHeight: '2.5rem',
              maxHeight: '2.5rem',
              flexDirection: 'row',
              flexGrow: 1
            }}>
            {(user?.is_admin || user?.is_procurement) && (
              <React.Fragment>
                <TabHeaderItem path="/crfs" label="All CRFs" icon={<AssignmentIcon />} />
                <TabHeaderItem
                  path="/crfs/pending"
                  label="All Pending"
                  icon={<AssignmentReturnedIcon />}
                />
                <TabHeaderItem
                  label="All Approved"
                  path="/crfs/approved"
                  icon={<AssignmentTurnedInIcon />}
                />
                <TabHeaderItem
                  path="/crfs/delaying"
                  label="All Delaying"
                  icon={<AssignmentLateIcon />}
                />
              </React.Fragment>
            )}

            {user?.is_approver && (
              <React.Fragment>
                <TabHeaderItem path="/crfs/mine" label="My CRFs" icon={<AssignmentIndIcon />} />
                <TabHeaderItem
                  path="/crfs/pending-my-approval"
                  label="Pending My Approval"
                  icon={<AssignmentReturnedIcon />}
                />
                <TabHeaderItem
                  label="Approved By Me"
                  path="/crfs/approved-by-me"
                  icon={<AssignmentTurnedInIcon />}
                />
              </React.Fragment>
            )}
          </List>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
