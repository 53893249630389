import { FC, ReactElement, ReactNode, Ref, forwardRef, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Slide
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { TransitionProps } from '@mui/material/transitions';

interface DialogBoxProps {
  open: boolean;
  handleClose: (event: any, reason?: any) => void;
  scroll: 'body' | 'paper' | undefined;
  children?: ReactNode;
  dialogTitle?: ReactNode;
  dialogActions?: ReactNode;
  showDialogTitle?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  fullScreen?: boolean;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogBox: FC<DialogBoxProps> = ({
  open,
  handleClose,
  fullScreen,
  maxWidth,
  scroll,
  children,
  dialogTitle,
  dialogActions,
  showDialogTitle
}) => {
  const [fullWidth] = useState(true);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      keepMounted
      TransitionComponent={Transition}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableEscapeKeyDown
      fullWidth={fullWidth}
      maxWidth={maxWidth ?? 'sm'}
      sx={{
        sm: {
          width: '100%',
          height: '100%'
        }
      }}
    >
      {showDialogTitle && (
        <DialogTitle id="scroll-dialog-title" sx={{ m: 0, padding: '5px 16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Box
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                flexDirection: 'row',
                marginRight: '16px',
                fontSize: '0.875rem',
                lineHeight: 1,
                fontWeight: 700
              }}
            >
              {dialogTitle}
            </Box>
            <Box
              sx={{
                flexShrink: 0,
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              {handleClose ? (
                <Button
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    padding: '2px',
                    width: '32px',
                    height: '32px',
                    maxWidth: '32px',
                    minWidth: '32px',
                    maxHeight: '32px',
                    minHeight: '32px',
                    borderRadius: '50%',
                    color: red[300],
                    bgcolor: grey[100],
                    '&:hover': {
                      bgcolor: grey[200],
                      color: red[500]
                    }
                  }}
                >
                  <CancelIcon />
                </Button>
              ) : null}
            </Box>
          </Box>
        </DialogTitle>
      )}
      <DialogContent dividers={scroll === 'paper'} sx={{ minHeight: 'auto', padding: '16px' }}>
        <Box sx={{ width: '100%', height: '100%' }}>{children}</Box>
      </DialogContent>
      {dialogActions && (
        <DialogActions sx={{ minHeight: 'auto', padding: '5px 16px' }}>
          <Box sx={{ width: '100%', height: '100%' }}>{dialogActions}</Box>
        </DialogActions>
      )}
    </Dialog>
  );
};
