import Cookies from 'universal-cookie';

/**
 * @public
 */

export const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    return new Error(fallbackMessage);
  };

/**
 * @public
 */

export const signInError = normalizeErrorFn('Sign in failed');

/**
 * @public
 */

export const signOutError = normalizeErrorFn('Sign out failed');

/**
 * @public
 */

export const clearCookies = () => {
  const cookies = new Cookies();
  cookies.remove('access_token_cookie');
  cookies.remove('refresh_token_cookie');
};

/**
 * @public
 */

export function isInBrowser() {
  return (
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined'
  );
}

/**
 * @public
 */

export function hasCookies() {
  return document.cookie.length > 0;
}
