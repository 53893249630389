import React from 'react';

import { Box, Typography } from '@mui/material';

import { DialogBox } from '@crf/ui/common';
import { ProcessingFab } from '@crf/ui/crf/components';

type HttpDialogProps = {
  isOpen: boolean;
  crf_name?: string;
  isError: boolean;
  isSuccess: boolean;
  isProcessing: boolean;
  dialogMessage: string;
  crf_id?: string | number;
  handleClose: () => void;
};

export const HttpDialog: React.FC<HttpDialogProps> = ({
  isOpen,
  handleClose,
  isError,
  isSuccess,
  isProcessing,
  dialogMessage,
  crf_id,
  crf_name
}) => {
  return (
    <DialogBox open={isOpen} handleClose={handleClose} scroll="paper">
      <Box display="flex" alignItems="center" flexDirection="column" gap={3} py={6}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ProcessingFab isError={isError} isSuccess={isSuccess} isProcessing={isProcessing} />
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
          <Box>
            <Typography
              fontSize=".75rem"
              fontWeight={600}
              letterSpacing=".04em"
              lineHeight="1"
              textAlign="center">
              {dialogMessage}
            </Typography>
          </Box>
          <Box>
            {crf_id && (
              <Typography
                fontSize=".625rem"
                fontWeight={600}
                textTransform="uppercase"
                letterSpacing=".04em"
                lineHeight="1"
                color="#667382"
                textAlign="center">
                CRF {crf_id}
              </Typography>
            )}
            {crf_name && (
              <Typography
                component="h2"
                margin={0}
                fontSize="0.875"
                lineHeight="1"
                fontWeight={600}
                color="inherit"
                display="flex"
                alignItems="center"
                textAlign="center">
                {crf_name}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </DialogBox>
  );
};
